<template>
  <div class="screen-register">
    <div class="container">
      <h2 class="page-title">Vul je gegevens in en starten maar!</h2>
    </div>
    <form @submit.prevent="register" @change="saveForm">
      <div class="container">
        <div class="form-group">
          <input
            v-model="participant.firstName"
            type="text"
            class="form-control no-border"
            placeholder="Voornaam"
            name="firstName"
          />
        </div>
        <div v-if="errorFirstName" class="alert alert-danger mt-3">
          {{ errorFirstName }}
        </div>
        <div class="form-group">
          <input
            v-model="participant.lastName"
            type="text"
            class="form-control no-border"
            placeholder="Achternaam"
            name="lastName"
          />
        </div>
        <div v-if="errorLastName" class="alert alert-danger mt-3">
          {{ errorLastName }}
        </div>
        <div class="form-group">
          <form-select
            v-model="participant.gender"
            :items="genderChoices"
            empty-text="Geslacht"
            select-arrow-background-color="#FFF"
            select-arrow-color="#009B6D"
            border-color="#FFF"
            name="gender"
          />
        </div>
        <div v-if="errorGender" class="alert alert-danger mt-3">
          {{ errorGender }}
        </div>
        <div class="form-group">
          <form-select
            v-model="participant.yearOfBirth"
            :items="yearOfBirthChoices"
            empty-text="Geboortejaar"
            select-arrow-background-color="#FFF"
            select-arrow-color="#009B6D"
            border-color="#FFF"
            name="gender"
          />
        </div>
        <div v-if="errorYearOfBirth" class="alert alert-danger mt-3">
          {{ errorYearOfBirth }}
        </div>
        <div class="consent-text">
          <p>
            Jonger dan 16 jaar? Door je te registreren geef je aan toestemming
            van je ouder/voogd te hebben.
          </p>
        </div>
        <!-- Do not show the phone numbers on Inviplay request -->
        <!--<div class="form-group">-->
        <!--<input type="number" class="form-control no-border" placeholder="Mobiel" v-model="participant.phoneNumber" pattern="[0-9]*" inputmode="numeric">-->
        <!--</div>-->
        <!--<div class="form-group">-->
        <!--<input type="text" class="form-control no-border" placeholder="Postcode 1234AB" required v-model="participant.postalCode" name="postalCode">-->
        <!--</div>-->
        <div class="form-group">
          <input
            v-model="participant.email"
            type="email"
            class="form-control no-border"
            placeholder="Email"
            name="email"
          />
        </div>
        <div v-if="errorEmail" class="alert alert-danger mt-3">
          {{ errorEmail }}
        </div>
        <div class="form-group">
          <input
            v-model="participant.password"
            type="password"
            class="form-control no-border"
            placeholder="Wachtwoord"
            name="password"
          />
        </div>
        <div v-if="errorPassword" class="alert alert-danger mt-3">
          {{ errorPassword }}
        </div>
        <div class="form-group">
          <form-checkbox
            v-model="participant.newsletterGeneral"
            label="Ik ontvang graag updates via e-mail over nieuwe mogelijkheden op Inviplay (sporadisch)"
            label-color="#FFF"
            name="newsLetterGeneral"
            checkbox-size="small"
          />
        </div>
        <div class="form-group">
          <form-checkbox
            v-model="participant.termsAndConditions"
            label=""
            label-color="#FFF"
            name="termsAndConditions"
            checkbox-size="small"
          >
            Ik ga akkoord met
            <a
              href="#"
              class="label-link"
              @click.prevent="
                $router.push('/terms-and-Conditions?hideTabBar=true')
              "
            >
              de algemene voorwaarden
            </a>
            en
            <a
              href="#"
              class="label-link"
              @click.prevent="$router.push('/privacy-policy?hideTabBar=true')"
            >
              privacy verklaring
            </a>
            van Inviplay
          </form-checkbox>
        </div>
        <div v-if="errorTermsAndConditions" class="alert alert-danger mt-3">
          {{ errorTermsAndConditions }}
        </div>
      </div>
      <div class="container">
        <button
          type="submit"
          class="btn btn-white btn-block button-register btn-round mt-5"
        >
          ga verder
        </button>
        <a
          href="#"
          class="btn btn-green-border-white btn-block button-login btn-round mt-3"
          @click.prevent="$router.back()"
        >
          terug naar sporten kiezen
        </a>
      </div>
    </form>
    <div class="background-entrance">
      <div class="bottom">
        <div class="circle" />
      </div>
    </div>
    <modal :show="showSuccesModal" @close="closeSuccessModal">
      <template v-slot:header>
        Je bent er bijna {{ participant.firstName }}!
      </template>
      <template>
        <p>
          Je hebt van ons een e-mail gekregen met daarin een link om je
          e-mailadres te bevestigen. Zodra je dat gedaan hebt kun je inloggen en
          een activiteit bij jouw in de buurt vinden of een zaaltje, baan of
          veld huren. En je kunt natuurlijk ook zelf activiteiten aanmaken.
          Invite & play!
        </p>
      </template>
      <template v-slot:footer>
        <button class="button button-primary w-full" @click="closeSuccessModal">
          Inloggen
        </button>
      </template>
    </modal>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <template v-slot:header>Oeps, er ging iets mis</template>
      <template>
        <p>
          Er gaat iets fout bij het registreren. Probeer het nog eens of neem
          contact met ons op via contact@inviplay.nl en we helpen je zo snel
          mogelijk verder.
        </p>
      </template>
      <template v-slot:footer>
        <button
          class="button button-primary w-full"
          @click="showErrorModal = false"
        >
          Sluiten
        </button>
      </template>
    </modal>
    <SpinnerLoader v-if="participantLoading" />
    <SpinnerLoader v-if="addressCoordinatesLoading" />
  </div>
</template>

<script>
import { ACTIVITY_LIST } from '../store/actions/activity'
import SpinnerLoader from '@/components/SpinnerLoader'
import Modal from '@/components/Modal'
import { mapState } from 'vuex'
import FormSelect from '@/components/FormSelect'
import FormCheckbox from '@/components/FormCheckbox'
import data from '@/utils/data'
import _ from 'lodash'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { PARTICIPANT_CREATE } from '../store/actions/participant'
import config from '@/utils/config'
import fbConversion from '@/utils/sendFacebookEvent'

export default {
  name: 'RegisterInfo',
  components: {
    FormSelect,
    FormCheckbox,
    Modal,
    SpinnerLoader
  },
  data() {
    return {
      errorFirstName: null,
      errorLastName: null,
      errorGender: null,
      errorYearOfBirth: null,
      errorEmail: null,
      errorPassword: null,
      errorTermsAndConditions: null,
      participant:
        localStorage.getItem('register.participant') !== null
          ? JSON.parse(localStorage.getItem('register.participant'))
          : {
              firstName: null,
              lastName: null,
              gender: null,
              yearOfBirth: null,
              email: null,
              password: null,
              postalCode: null,
              phoneNumber: null,
              termsAndConditions: false,
              newsletterGeneral: false,
              rangeChoice: null
            },
      genderChoices: data.genderChoices,
      yearOfBirthChoices: data.getYearOfBirthChoices(),
      searchAddress: null,
      showSuccesModal: false,
      showErrorModal: false,
      usernameExists: false,
      participantActivities:
        localStorage.getItem('register.activities') !== null
          ? JSON.parse(localStorage.getItem('register.activities'))
          : [],
      creditcardsEnabled: config.featureFlags.creditcards
    }
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activity.activityList,
      participantLoading: (state) => state.participant.status === 'loading',
      addressCoordinatesLoading: (state) => state.location.status === 'loading'
    })
  },
  created: function () {
    this.$store.dispatch(ACTIVITY_LIST)
  },
  methods: {
    resetErrors() {
      this.errorFirstName = null
      this.errorLastName = null
      this.errorGender = null
      this.errorYearOfBirth = null
      this.errorEmail = null
      this.errorPassword = null
      this.errorTermsAndConditions = null
    },
    saveForm() {
      this.resetErrors()
      localStorage.setItem(
        'register.participant',
        JSON.stringify(this.participant)
      )
    },
    passwordIsValid(password) {
      // Check if password contains at least one capital letter and one number
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
      return password.match(re)
    },
    isValid: function () {
      let isValid = true
      if (_.isEmpty(this.participant.firstName)) {
        isValid = false
        this.errorFirstName = 'Voornaam is verplicht'
      } else if (_.isEmpty(this.participant.lastName)) {
        isValid = false
        this.errorLastName = 'Achternaam is verplicht'
      } else if (_.isEmpty(this.participant.gender)) {
        isValid = false
        this.errorGender = 'Geslacht is verplicht'
      } else if (_.isEmpty(this.participant.yearOfBirth)) {
        isValid = false
        this.errorYearOfBirth = 'Geboortejaar is verplicht'
      } else if (_.isEmpty(this.participant.email)) {
        isValid = false
        this.errorEmail = 'Email is verplicht'
      } else if (_.isEmpty(this.participant.password)) {
        isValid = false
        this.errorPassword = 'Wachtwoord is verplicht'
      } else if (!this.passwordIsValid(this.participant.password)) {
        isValid = false
        this.errorPassword =
          'De lengte van het wachtwoord moet minstens 8 tekens zijn. Gebruik hoofd- en kleine letters en cijfers.'
      } else if (!this.participant.termsAndConditions) {
        isValid = false
        this.errorTermsAndConditions =
          'Je dient akkoord te gaan met de algemene voorwaarden als je je wilt aanmelden voor Inviplay'
      }
      return isValid
    },
    register: async function () {
      if (this.isValid()) {
        try {
          const response = await this.$store.dispatch(PARTICIPANT_CREATE, {
            participant: this.participant,
            activities: this.participantActivities,
            query: this.$route.query
          })
          if (response.status === 200) {
            localStorage.removeItem('register.participant')
            localStorage.removeItem('register.activities')
            fbConversion({
              event: 'Register',
              user: this.participant,
              url: `${config.appUrl}${this.$route.path}`
            })
            this.$ga.event('Account', 'register')
            this.showSuccesModal = true
          } else {
            this.showErrorModal = true
          }
        } catch (error) {
          if (
            error.response?.data?.error?.details?.codes?.username.includes(
              'USERNAME_ALREADY_EXISTS'
            )
          ) {
            this.usernameExists = true
            this.showErrorModal = true
          } else {
            this.showErrorModal = true
          }
          // const errorCode = error.response?.data?.errorCode
          this.showErrorModal = true
        }
      }
    },
    closeSuccessModal: function () {
      this.showSuccesModal = false
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.page-title {
  font-size: 2rem;
  color: white;
  text-align: center;
  margin: 2rem 0 3rem;
  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
}
.page-title-green {
  color: $ipGreen;
}
.screen-register .logo {
  width: 270px;
  margin: 0 auto;
  padding: 40px 0;
  max-width: 100%;
}
.screen-register .logo img {
  width: 270px;
  max-width: 100%;
}
.screen-register .register-progress-indicator {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2.5rem;
}

.consent-text {
  color: $ipWhite;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-weight: inherit;
  text-align: left;
}

.screen-register .register-progress-indicator h2 {
  color: $ipWhite;
}

.screen-register .text-sports {
  color: $ipWhite;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 15px 0;
  padding: 0 15px;
  text-align: center;
}
.screen-register .text-or {
  color: $ipWhite;
  font-size: 1.25rem;
  margin: 15px 0;
  text-align: center;
}
.screen-register .wrapper-activity-list .activity-list li .activity-title {
  color: $ipWhite;
}
.screen-register .button-register {
  margin-top: 15px;
}
.screen-register .button-login {
  margin-bottom: 25px;
}
.screen-register .form-text {
  color: $ipWhite;
}
.screen-register .label-link {
  text-decoration: underline;
  color: $ipWhite;
}
</style>
